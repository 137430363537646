module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 't2xh3w5p',
    dataset: process.env.GATSBY_SANITY_DATASET || 'pcade-sp22-prblt',
  },
  gatsby: {
    siteTitle: 'Academy of Art University | PCAE Final Exhibition',
    siteDescription:
      'Academy of Art University is proud to present some of the best works from this year’s batch. May this serve as inspiration to other young high school students who would like to discover and further refine their artistic talents.',
    siteUrl: 'https://pcae.academyart.edu', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
    userTwitter: '@academy_of_art', // Change for Twitter Cards
    author: 'Academy of Art University', // Author for RSS author segment and SEO schema
    authorUrl: 'https://www.academyart.edu', // URL used for author and publisher schema, can be a social profile or other personal site
    shortTitle: 'PCAE Final Exhibition', // Used for App manifest e.g. Mobile Home Screen
  },
};
